import { useStorage } from '@_plugins/storage';
import { API } from '@data/models/api.interface';
import useStore from '@stores/store';
import axios, { AxiosRequestConfig } from 'axios';
import { HttpErrorResponse } from 'data/models/error.interface';
import { FilterParams } from 'data/models/filter.interface';
import { injectable } from 'inversify';
import 'reflect-metadata';

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const httpErrorResponse: HttpErrorResponse = error?.response?.data;
    return { error: httpErrorResponse };
  },
);

@injectable()
export default class LaravelAPI implements API {
  public get<T extends object>(
    url: string,
    qs?: FilterParams,
  ): Promise<{ data?: T; error?: HttpErrorResponse }> {
    const { filter, orderBy, orderDirection, ...params } = qs || {};

    const apiParams: {
      'page[size]': number | undefined;
      'page[number]': number | undefined;
      include?: string;
      sort?: string;
      [key: string]: string | number | string[] | undefined;
    } = {
      ['include']: params.include?.join(',') || '',
      ['page[size]']: params.itemsPerPage,
      ['page[number]']: params.page,
    };

    if (orderBy) {
      apiParams.sort = `${(orderDirection === 'desc' && '-') || ''}${orderBy}`;
    }

    return LaravelAPI.send(url, {
      params: Object.keys(filter || {}).reduce((acc, cur) => {
        if (!filter || !cur) {
          return acc;
        }

        const value = filter[cur];

        if (!value) {
          return acc;
        }

        acc[`filter[${cur}]`] = filter[cur];
        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, apiParams as any),
      method: 'GET',
    });
  }

  public patch<T extends object, U extends object>(
    url: string,
    body: Partial<T>,
  ): Promise<{ data?: U; error?: HttpErrorResponse }> {
    return LaravelAPI.send(url, {
      data: body,
      method: 'PATCH',
    });
  }

  public post<T extends object, U extends object>(
    url: string,
    body: Partial<T>,
  ): Promise<{ data?: U; error?: HttpErrorResponse }> {
    return LaravelAPI.send(url, {
      data: body,
      method: 'POST',
    });
  }

  public delete<T extends object>(
    url: string,
  ): Promise<{ data?: T; error?: HttpErrorResponse; status?: number }> {
    return LaravelAPI.send(url, {
      method: 'DELETE',
    });
  }

  private static async send(
    url: string,
    config: AxiosRequestConfig,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const storage = useStorage();
    const token = storage.get('TOKEN_SESSION_NAME');

    if (useStore.getState().inactive) {
      return;
    }

    return axios(url, {
      responseType: 'json',
      baseURL: process.env.VITE_APP_LARAVEL_URL,
      headers: {
        Authorization: `Bearer ${String(token)}`,
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      ...config,
    });
  }
}
